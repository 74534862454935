.page {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 200px;
  justify-content: center;
  align-items: center;

  div {
    width: 300px;
  }
}

.home,
.camp,
.signin,
.signup,
.registration,
.admin-signup,
.verification,
.change-password {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../public/wc-bg.png");
  background-size: cover;
  background-position: center;
}

.header {
  flex: 0 0 auto;
  padding: 20px;
}

.logo {
  height: 30px;
}

.content {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .heading {
    font-size: 50px;
    text-align: center;
  }

  .subheading {
    font-size: 20px;
    max-width: 600px;
    text-align: center;
  }

  .floating-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;

    @media (max-width: 1024px) {
      display: none;
    }

    .img00,
    .img01,
    .img02 {
      position: absolute;
      animation: float 10s infinite ease-in-out;
    }

    .img02 {
      left: 20%;
      top: 10%;
      max-width: 400px;
    }

    .img01 {
      right: 20%;
      top: 20%;
      max-width: 80px;
    }

    .img00 {
      left: 10%;
      bottom: 00%;
      max-width: 400px;
    }

    @keyframes float {
      0%,
      100% {
        transform: translateY(0);
        transform: rotate(05deg);
      }
      50% {
        transform: translateY(-20px);
      }
    }
  }
}

.logged-in-account {
  display: flex;
  flex-direction: row;
  max-height: 200px;
  padding: 20px;
  background-color: transparent;
  gap: 20px;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  overflow: scroll;

  .user-card {
    max-width: 300px;
    min-width: 200px;
    max-height: 200px;
    overflow: hidden;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .close-btn {
      padding: 5px;
    }
  }
}
