@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/ProductSans/ProductSansThin.ttf') format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/ProductSans/ProductSansLight.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/ProductSans/ProductSansRegular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/ProductSans/ProductSansMedium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/ProductSans/ProductSansBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/ProductSans/ProductSansBlack.ttf')
    format('truetype');
}


body {
  margin: 0;
  font-family: 'Product Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
